.dzu-dropzone {
    overflow: hidden !important;
    border-style: dashed !important;
}

.dzu-inputLabel,
.dzu-inputLabelWithFiles {
    color: #484C7F !important;
}

.dzu-submitButton {
    background-color: #484C7F !important;
    color: white !important;
}

.avatar.no-thumbnail {
    background-color: transparent !important;
}

.capitalize-sentence::first-letter {
    text-transform: uppercase;
}

@media (min-width: 576px){
.modal-dialog-updated {
    max-width: 54%!important;
    margin-right: auto;
    margin-left: auto;
}
}

.sidebar-mini .logo-text{
    font-size: 11px;
}

.taskboard .progress_task .dd-item:before {
    background-color: transparent!important; 
}